<template>
  <div>
    <b-overlay :show="localPostOverLay || localLocationIsLoad" rounded="sm" class="dashboard-overlay-reviews">
      <div>
        <b-card>
          <b-row class="mainLocalPostContainer">
            <b-col cols="4" class="mainLocalPostContainerItem">
              <b-card-title>
                <h4 class="content-header-title float-left pr-1 mb-0" style="text-transform: capitalize;">
                  {{ this.clientName }} Stores
                </h4>
              </b-card-title>
              <br />
              <b-card-title>
                <h5 class="float-left pr-1 mb-0">
                  You've {{ this.count }} stores across the India.
                </h5>
              </b-card-title>
            </b-col>
            <b-col cols="3" class="mainLocalPostContainerItem">
              <div class="form-group">
                <label for="clientSelect" class="form-label">Client:</label>
                <div class="select-container">
                  <b-form-select id="clientSelect" v-model="selectedSlug" @change="getAllData(true, false)">
                    <option v-for="onetype in fetchClientsList" :value="onetype.slug" class="form-control" id="lg_type"
                      name="lg_type" v-bind:key="onetype.slug">{{ onetype.name }}</option>
                  </b-form-select>
                </div>
              </div>
            </b-col>
            <b-col cols="3" class="mainLocalPostContainerItem">
              <div class="form-group">
                <label for="loctionSelect" class="form-label">Location Groups:</label>
                <div class="select-container">
                  <b-form-select id="loctionSelect" v-model="selectedLocationGroup" @change="getAllData(true, true)">
                    <option v-for="onetype in allgmbAccounts" :value="onetype.name.substring(9)" class="form-control"
                      id="lg_type" name="lg_type" v-bind:key="onetype.name.substring(9)">{{ onetype.accountName }}
                    </option>
                  </b-form-select>
                </div>
              </div>
            </b-col>
            <b-col cols="2" class="mainLocalPostContainerItem">
              <div class="create-post-button">
                <b-button variant="primary" @click="openLocalHost" style="font-size: 11px;">Create Post <feather-icon
                    icon="PlusIcon" size="11" style="margin-left: 2px;" /></b-button>
              </div>
            </b-col>
          </b-row>
        </b-card>

        <div>
          <b-card>
            <div class="schedule-hearder">
              <b-card-title>
                <h3 class="app-title mdsm">Storewise Local Post</h3>
              </b-card-title>
              <input type="text" v-model="searchTerm" placeholder="Search by columns name." />
            </div>
            <div v-if="isLoadingIndividualData" class="spinner">
              <b-spinner small></b-spinner>
            </div>
            <vue-good-table class="custom-data-table" :rows="locationData" :columns="IndividualDatatableColumns"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }" :pagination-options="{
  enabled: true,
  perPage: pageLength,
}">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.label == 'View/Update Post'">
                  <button class="EditButton" @click="getViewLocalPostData(props)">
                    <feather-icon icon="EyeIcon" class="customizeIcon" size="20" stroke-width="2"
                      color="#6C4E96"></feather-icon>
                  </button>
                </span>
                <div v-if="props.column.label == 'Store Address'">
                  <span class="tooltip-trigger overflow-text" :title="props.row.address">
                    {{ props.row.address }}
                  </span>
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap mt-2">
                  <div class="d-flex align-items-center mb-0 mt-1"></div>
                  <div>
                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                      align="right" prev-class="prev-item" next-class="next-item" class="mb-0"
                      v-model="paginatedDataNumber2" @input="(value) => props.pageChanged({ currentPage: value })">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
          <LocalPostModal :accountID="this.selectedLocationGroup" :clientID="this.selectedClientId"
            :localStorageModal="editClientScreen" @closeClientPopUp="closeClientPopUp"
            @openCreatePostModal="createPostModal" :clientName="this.clientName" :showModal="showModal"
            @closeModal="closeModal" :viewData="this.viewLocalPostData" :locationId="this.storeLocationID"
            :storeName="this.storeName" :isEditLoad="editLoader" :phoneNumber="this.callNum"
            :isEmptyArray="isEmptyArray" />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardBody,
  BFormSelect,
  BCardTitle,
  BPagination,
  BBreadcrumb,
  BBreadcrumbItem,
  BAvatar,
  BSpinner,
  BOverlay
} from "bootstrap-vue";
import vSelect from "vue-select";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LocalPostModal from "@/components/client/LocalPostModal.vue";

export default {
  name: "gmb",
  components: {
    BSpinner,
    BCol,
    BRow,
    BCard,
    BButton,
    vSelect,
    BCardBody,
    BFormSelect,
    VueGoodTable,
    BCardTitle,
    BPagination,
    BBreadcrumb,
    BBreadcrumbItem,
    BAvatar,
    LocalPostModal,
    BOverlay
  },
  props: {
    customGmbValue: {
      type: String,
    },
    inputLocalIdProps: {
      type: Object
    },
    isThroughGmbDash: {
      type: Boolean
    }
  },
  data() {
    return {
      setIntialOnly: false,
      isEmptyArray: 0,
      localPostOverLay: false,
      localLocationIsLoad: false,
      callNum: "",
      viewLocalPostData: [],
      storeLocationID: '',
      storeName: '',
      showModal: false,
      viewOpenModal: false,
      editClientScreen: false,
      createPostModal: false,
      customIntialValue: true,
      editLoader: false,
      selectedMonth: 4,
      allgmbAccounts: [],
      searchTerm: "",
      count: 0,
      locationData: [],
      SummarytableData: [],
      fetchClientsList: [],
      pageLength: 5,
      summmaryPageLength: 10,
      organicVsPaidLenghth: 5,
      effectivenessDataLength: 5,
      paginatedDataNumber: 1,
      paginatedDataNumber2: 1,
      paidAdsEffectiveness: 5,
      selectedClientId: "",
      selectedSlug: "",
      clientName: "",
      selectedLocationGroup: "",
      id: "",
      client: {
        status: false,
        integrations: {
          googleAds: null,
        },
        googleAdsCustomerID: null,
      },
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      clients: [],
      selected: null,
      opens: true,
      isLoadingIndividualData: false,
    };
  },
  methods: {
    getViewLocalPostData(props) {
      this.isEmptyArray = 0;
      let locationId = props.row.locationId
      let callNumber = props.row.phoneNumbers?.primaryPhone || ""
      this.callNum = callNumber;
      this.storeLocationID = locationId;
      this.storeName = props.row.name
      this.showModal = true;
      this.editLoader = true;
      this.viewLocalPostData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/localPost/get?accountID=${this.selectedLocationGroup}&clientID=${this.selectedClientId}&locationID=${locationId}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.viewLocalPostData = response.data.localPosts;
          this.isEmptyArray = this.viewLocalPostData.length;
          this.editLoader = false;
        })
        .catch((error) => {
          console.log(error);
          this.editLoader = false;
        });
    },
    getLocationData() {
      this.localLocationIsLoad = true
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/gmb/stores?accountID=${this.selectedLocationGroup}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.locationData = response.data.stores;
          this.count = this.locationData.length;
          this.localLocationIsLoad = false
        })
        .catch((error) => {
          this.localLocationIsLoad = false
          console.log(error);
        });
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    openLocalHost() {
      this.editClientScreen = true;
    },
    openViewModal() {
      this.viewOpenModal = true;
    },
    closeClientPopUp() {
      this.editClientScreen = false;
    },
    setIntialClient() {
      if (this.customIntialValue) {
        this.selectedSlug = this.$route.params.slug;
        // console.log(this.inputLocalIdProps.slug , this.selectedSlug,' ---- ---');
        if (!this.$route.params.slug) {
          if (this.isThroughGmbDash == true) {
            this.setIntialOnly = true
            this.selectedSlug = this.inputLocalIdProps.slug;
            this.selectedClientId = this.inputLocalIdProps._id;
          }
          if (this.isThroughGmbDash == false) {
            this.setIntialOnly = true
            this.selectedSlug = this.fetchClientsList[0].slug;
            this.selectedClientId = this.fetchClientsList[0]._id;
          }

        }
        // this.getLocationData();
        this.getAllData(true, false);
        this.customIntialValue = false;
      }
    },
    fetchGMBCustomers() {
      if (!this.selectedClientId) {
        return;
      }
      // this.localPostOverLay = true 
      this.allgmbAccounts = []
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/gmb/accounts`, {
          clientID: this.selectedClientId,
        }, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.allgmbAccounts = response.data.accounts;

        })
        .catch((error) => {
          console.log(error.response.data);
          this.client.status = false;
          // this.localPostOverLay = false 
          this.errorMessage = error.response.data.error;
          this.allgmbAccounts = []
        });
    },
    getAllData(slugChanged = false, locationGroupChanged = false) {
      this.isLoadingIndividualData = true;
      let slug = "";
      if (!slugChanged) {
        slug = this.$route.params.slug;
      } else {
        slug = this.selectedSlug;
      }
      if (!locationGroupChanged) {
        axios
          .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          })
          .then((response) => {
            this.client = { ...response.data.client, status: true };
            this.selectedClientId = this.client._id;
            this.selectedSlug = this.client.slug;
            this.clientName = this.client.name;
            if (!locationGroupChanged) {
              this.selectedLocationGroup = this.client.gmb.accountID;
              if (Array.isArray(this.client.gmb.accountID)) {
                this.selectedLocationGroup = this.selectedLocationGroup[0];
              }
            }
            this.getLocationData();
            this.fetchGMBCustomers();
            this.isLoadingIndividualData = false;
          })
          .catch((error) => {
            console.log(error.response.data);
            this.isLoadingIndividualData = false;
            this.client.status = false;
            this.errorMessage = error.response.data.error;
            this.showToast("Error", error.response.data.error, "danger");
          });
      } else {
        this.getLocationData();
        this.fetchGMBCustomers();
      }
      // axios
      // .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
      //   headers: {
      //     Authorization: `Bearer ${getUserToken()}`,
      //   },
      // })
      // .then((response) => {
      //   this.client = { ...response.data.client, status: true };
      //   this.selectedClientId = this.client._id;
      //   this.selectedSlug = this.client.slug;
      //   this.clientName = this.client.name;
      //   if (!locationGroupChanged) {
      //     this.selectedLocationGroup = this.client.gmb.accountID;
      //     if (Array.isArray(this.client.gmb.accountID)) {
      //       this.selectedLocationGroup = this.selectedLocationGroup[0];
      //     }
      //   }
      //   this.getLocationData();
      //   // this.fetchClients();
      //   this.fetchGMBCustomers();
      //   this.isLoadingIndividualData = false;
      // })
      // .catch((error) => {
      //   console.log(error.response.data);
      //   this.isLoadingIndividualData = false;
      //   this.client.status = false;
      //   this.errorMessage = error.response.data.error;
      //   this.showToast("Error", error.response.data.error, "danger");
      // });
      this.isLoadingIndividualData = false;
    },
    fetchClients() {
      this.localPostOverLay = true
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/get`,
          {
            limit: 50,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.localPostOverLay = false
          this.setIntialOnly = false
          this.fetchClientsList = response.data.clients.filter((client) => {
            return client.gmb && client.gmb.accountID.length;
          });

          this.setIntialClient();
        })
        .catch((error) => {
          this.localPostOverLay = false
          console.log(error);
        });
    },
    runAllFunc() {
      this.getAllData(false, false);
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
  },
  computed: {
    IndividualDatatableColumns() {
      return [
        {
          label: "Store code",
          field: "storeCode",
          filterable: true,
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Address",
          field: "address",
          filterable: true,
          thClass: "text-center",
          class: "overflow-text",
          tdClass: "text-center",
        },
        {
          label: "View/Update Post",
          thClass: "text-center",
          tdClass: "text-center",
          field: "-",
        },
      ];
    },
  },
  mounted() {
    // document.getElementById("custom-Stores").classList.add("active")
    if (this.customIntialValue) {
      this.fetchClients();
    } else {
      this.getAllData(false, false);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.comparison-dashboard-link {
  font-size: 16px;
  font-weight: 500;
  color: #200e6b;
}

.vue-daterange-picker {
  width: 100%;

  svg {
    color: #4d21ff;
  }
}

.reportrange-text {
  display: flex;
  align-items: center;
}

.date-range {
  font-size: 11px !important;
}

.picker-controller {
  background-color: #f5f6fa;
}

.date-range-picker-container {
  background-color: #f5f6fa;
  padding: 8px;
  width: 240px;
}

.main-gmb-container {
  padding: 0 13%;
}

.schedule-hearder {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

input {
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  width: 205px;
  height: 38px;
  background-color: white;
  border: 1px solid rgb(218, 211, 211);
  color: rgb(163, 153, 153);
}

.spinner {
  justify-content: space-around;
  display: flex;
  left: auto;
  right: auto;
  margin-bottom: 10px;
}

.up-icon {
  margin-left: 10px;
  color: green;
  align-items: center;
}

.down-icon {
  margin-left: 10px;
  color: red;
  align-items: center;
}

.collapse-icon {
  color: grey;
  float: right;
  cursor: pointer;
}

.custom-name-column {
  display: block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.overflow-text {
  display: block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.spanContainer {
  width: 100px;
  display: flex;
  justify-content: space-around;
}

.custom-select option:disabled {
  background-color: #efefef;
  color: #b8c2cc;
}

.create-post-button {
  float: right;
  margin-top: 25px;
}

.EditButton {
  justify-content: center;
  align-items: center;
  border: none;
  background-color: white;
}

.form-group {
  display: block !important;
}

.form-label {
  font-size: 12px !important;
}

@media only screen and (max-width: 767px) {
  .mainLocalPostContainer {
    display: grid;
    grid-template-columns: auto;

  }

  .mainLocalPostContainerItem {
    width: 1300px;
  }

  .create-post-button {
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
